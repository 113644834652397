<template>
  <div class="authorization">
    <form class="authorization__form" action="" @submit.prevent="authorization">
      <div class="authorization__item">
        <label for="">Email</label>
        <b-input
          v-model="form.email"
          type="email"
          class="authorization__input main-input"
          placeholder="Введите свой e-mail"
          required
        />
      </div>
      <div class="authorization__item">
        <label for="">Пароль</label>
        <b-input
          v-model="form.password"
          :type="iconShow ? 'password' : 'text'"
          minlength="3"
          class="authorization__input main-input"
          placeholder="Пароль"
          required
        />
        <b-icon
          class="authorization__input-icon"
          :icon="iconShow ? 'eye' : 'eye-slash'"
          @click="iconShow = !iconShow"
        />
      </div>
      <div class="authorization__info flex">
        <div class="flex">
          <input v-model="stranger" type="checkbox" class="authorization__checkbox" />
          <label>Чужой компьютер</label>
        </div>
        <p class="authorization__password-forgot" @click="$router.push('/auth/password-forgot')">
          Забыли пароль?
        </p>
      </div>
      <button type="submit" class="authorization__btn main-btn">
        Войти на платформу TMS
        <b-icon class="authorization__btn-icon" icon="arrow-right" />
      </button>
    </form>
  </div>
</template>

<script>
import Cookies from "js-cookie";
// import { myMixin } from "../../../mixins/error.js";
export default {
  // name: 'Home',
  // mixins: [myMixin],
  data() {
    return {
      iconShow: true,
      stranger: false,
      form: {
        email: "",
        password: "",
      },
    };
  },
  mounted() {
    if (Cookies.get("email") && Cookies.get("password")) {
      this.form.email = Cookies.get("email");
      this.form.password = Cookies.get("password");
    }
    if (Cookies.get("email") && Cookies.get("password")) {
      this.form.email = Cookies.get("email");
      this.form.password = Cookies.get("password");
    }
  },
  methods: {
    async authorization() {
      const formData = new FormData();
      formData.append("email", this.form.email);
      formData.append("password", this.form.password);
      this.$api
        .post("/web/login-employee", formData)
        .then((response) => {
          Cookies.set("employee_token", response.data.data.token);
          Cookies.set("position_id", response.data.data.position_id);
          this.$store.dispatch("getProfileProducts");
          if(response.data.data.position_id == 5){ // medics id
            this.$router.push("/medic");
          }else{
            this.$router.push("/");
          }
        })
        .catch((e) => {
          this.$toast.error(e.response.data?.message);
          // this.$store.commit("setToastData", {
          //   text: e.response.data.message,
          //   position: "error",
          // });
          // this.$toast.error(<toast-block />);
          // if ((e.message.split(" ").reverse()[0] === 500) || (e.message.split(" ").reverse()[0] === 401)) {
          //   this.$router.push(`/error/${e.message.split(" ").reverse()[0]}`);
          // }
        });
      this.$apiNavixy
        .get("/user/auth", {
          params: {
            login: process.env.VUE_APP_NAVIXY_LOGIN,
            password: process.env.VUE_APP_NAVIXY_PASSWORD,
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data && response.data.success === true)
            Cookies.set("navixy_hash", response.data.hash);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    strangerChange() {
      if (this.stranger) {
        Cookies.set("email", this.form.email);
        Cookies.set("password", this.form.password);
      } else {
        Cookies.set("email", this.form.email, { expires: 14 });
        Cookies.set("password", this.form.password, { expires: 14 });
      }
    },
  },
};
</script>

<style scoped>
.authorization {
  margin: 40px 0 30px;
  width: 100%;
}
.authorization__item {
  width: 100%;
  position: relative;
}
.authorization__info {
  width: 100%;
  font-weight: 600;
}
.authorization__checkbox {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}
.authorization__password-forgot {
  cursor: pointer;
}
.authorization__btn {
  position: relative;
  margin: 30px 0 0 0;
  /* width: 100%;
    height: 50px;
    background: #306CDC;
    border-radius: 10px;
    color: rgb(255, 255, 255); */
}
.authorization__btn-icon,
.authorization__input-icon {
  position: absolute;
  right: 20px;
}
.authorization__input-icon {
  top: 55%;
}
</style>
